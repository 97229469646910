<div class="logo">
  <a href="javascript:void(0)">
    <img
      alt="Logo"
      src="assets/images/viooh_logo.png"
      style="max-height: 60px"
    />
  </a>
</div>
<div class="dataLoadUtils">Data Load Utils</div>
