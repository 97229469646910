import { HttpParams } from '@angular/common/http';

/**
 * @description Returns formdata of given params
 * @author Shivani Patel
 * @export
 * @param {*} params
 * @returns {HttpParams}
 */
export function getFormData(params: { [key: string]: any }): HttpParams {
  let httpParams = new HttpParams();
  Object.keys(params).forEach((key: string) => {
    httpParams = httpParams.append(key, params[key]);
  });
  return httpParams;
}
