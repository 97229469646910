import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GLOBAL } from '../app.global';
import { HeaderService } from '../components/header/header.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(
    private appHeaderService: HeaderService,

  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.handleIntercept(GLOBAL.ACCESS_TOKEN, req, next);
  }

  private handleIntercept(token: string, req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header.
    let authReq: any;
    const noAuth = req.headers.get('noAuth');
    if (!token || noAuth) {
      if (noAuth) {
        // @ts-ignore
        req.headers = req.headers.delete('noAuth');
      }
      authReq = req.clone();
    } else {
      authReq = req.clone({
        setHeaders: { Authorization: `Bearer ${token}` }
      });
    }
    if (req.url.toLowerCase().indexOf('service') !== -1) {
      const SESSIONID = String(GLOBAL.BosSessionId);
      authReq.headers = authReq.headers.set('Bos-SessionId', SESSIONID);
    }
    return next.handle(authReq).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          this.requestSuccess(event);
        }
      }, (err: HttpErrorResponse) => {
        if (err.status === 401 || err.status === 403 || err.status === 404) {
          GLOBAL.ACCESS_TOKEN = '';
          const domain = this.appHeaderService.getCookie('domain');
          this.appHeaderService.setCookie('status_code', String(err.status), 1, domain);
          location.assign(environment.landingAppUrl);
        }
        if (err.status !== 200) {
          this.requestError(err);
        }
      }));
  }

  /**
   * Request success handler
   * @param res Respose object
   * @returns Response object
   */
  private requestSuccess(res: HttpResponse<any>) {
    if (res.headers.get('Bos-SessionId') !== null) {
      GLOBAL.BosSessionId = res.headers.get('Bos-SessionId');
    }
    if (res.body.messageCode === -100 || res.body.messageCode === -101) {
      window.location.reload();
    }
    return res;
  }

  /**
   * open Session timeout modal popup
   */
  private requestError(err: HttpErrorResponse) {
    console.log("sesion time out");
    if (err.status === 401 || err.status === 403 || err.status === 404) {
      GLOBAL.ACCESS_TOKEN = '';
      const domain = this.appHeaderService.getCookie('domain');
      this.appHeaderService.setCookie('status_code', String(err.status), 1, domain);
      location.assign(environment.landingAppUrl);
      return;
    }
    alert(err.message);
    window.location.reload();
  }
}
