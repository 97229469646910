import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GLOBAL } from 'src/app/app.global';
import { environment } from 'src/environments/environment';
import { version } from '../../../../package.json';
import { HeaderService } from '../header/header.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  @Input() systemdata: any;
  @Input() userbundle: any;
  @Input() userData: any = {};
  frontEndVersion = '';
  user = '';
  selectedMarket = '';
  isMarketChangeble = false;
  constructor(private appHeaderService: HeaderService) {}

  ngOnInit(): void {
    this.frontEndVersion = version;

    this.appHeaderService.cookieReceived$.subscribe(() => {
      this.user = GLOBAL.USER;
      this.selectedMarket = GLOBAL.MARKET;
      this.isMarketChangeble = GLOBAL.MARKET_CHANGABLE;
    });
  }

  handleRefreshEnvironment() {
    if (this.isMarketChangeble) {
      window.location.replace(environment.landingAppUrl);
    }
  }
}
