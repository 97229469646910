import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private marketSource: Subject<string> = new Subject();
  private cookieSource: Subject<string> = new Subject();
  public marketChanged$: Observable<string> = this.marketSource.asObservable();
  public cookieReceived$: Observable<string> = this.cookieSource.asObservable();

  constructor(private http: HttpClient) {}

  updateMarket(market: string) {
    this.marketSource.next(market);
  }

  cookieUpdated() {
    this.cookieSource.next();
  }

  getCookie(cname) {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  setCookie(
    name: string,
    value: string,
    expireDays: number,
    domain = '',
    path = ''
  ) {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    const cpath = path ? `path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}; ${cpath}; domain=${domain}`;
  }

  getLandingConfig(configURL) {
    const requestOptions = {
      headers: new HttpHeaders({
        noAuth: 'true',
      }),
    };
    return this.http.request(
      'GET',
      `${environment.landingAppUrl}/${configURL}?timestamp=${Date.now()}`,
      requestOptions
    );
  }
}
