import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { GLOBAL } from 'src/app/app.global';
import { environment } from 'src/environments/environment';
import { HeaderService } from '../header/header.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
})
export class FileUploadComponent implements OnInit {
  public title = 'DataLoadUtils';
  public uploader: FileUploader;
  public hasBaseDropZoneOver: boolean;
  public hasAnotherDropZoneOver: boolean;
  public response: string;
  public BOS_SESSIONID;
  public selectedItem = '-1';

  constructor(
    private toasterService: ToasterService,
    private appHeaderService: HeaderService
  ) {}

  ngOnInit() {
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;
    this.response = '';
    this.uploader = new FileUploader({});

    this.appHeaderService.cookieReceived$.subscribe(() => {
      this.initUploader();
    });
  }

  initUploader() {
    this.uploader = new FileUploader({
      url: environment.api + '/Service/FileProcessor',
    });
    this.uploader.authToken = `Bearer ${GLOBAL.ACCESS_TOKEN}`;
    this.uploader.response.subscribe((res) => (this.response = res));

    this.uploader.onBeforeUploadItem = (item) => {
      if (this.BOS_SESSIONID) {
        item.headers['Bos-SessionId'] = this.BOS_SESSIONID;
      }
    };

    this.uploader.onBuildItemForm = (item: FileItem, form: any) => {
      form.append('bosFileType', item.formData.bosFileType);
    };

    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };

    this.uploader.onSuccessItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: ParsedResponseHeaders
    ) => {
      const actualResponse = JSON.parse(response);
      if (actualResponse?.status && actualResponse.status === 'KO') {
        item.isError = true;
        item.isSuccess = false;
        item.isCancel = false;
        this.toasterService.pop('error', 'File Upload', 'Failed to upload the file');
      } else {
        this.BOS_SESSIONID = headers['bos-sessionid'];
        this.toasterService.pop('success', 'File Upload', 'File uploaded successfully');
      }
    };
  }

  public onSelectFileType(item: FileItem, selectedItem) {
    item.formData.bosFileType = selectedItem;
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }
}
