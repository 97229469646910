
<div class="container">
  <div class="row">
    <div class="col-md-3">
      <h3>Select files</h3>

      <div
        ng2FileDrop
        [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
        (fileOver)="fileOverBase($event)"
        [uploader]="uploader"
        class="well my-drop-zone"
      >
        File Drop Area
      </div>

      <br /><br />

      Multiple
      <input type="file" ng2FileSelect [uploader]="uploader" multiple /><br />
      <br /><br />
      Single
      <input type="file" ng2FileSelect [uploader]="uploader" />
    </div>

    <div class="col-md-9" style="margin-bottom: 40px">
      <h3>Upload queue</h3>
      <p>Queue length: {{ uploader?.queue?.length }}</p>

      <table class="table">
        <thead>
          <tr>
            <th width="50%">Name</th>
            <th>Size</th>
            <th>Progress</th>
            <th>Status</th>
            <th>File Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of uploader.queue">
            <td>
              <strong>{{ item?.file?.name }}</strong>
            </td>
            <td *ngIf="uploader.options.isHTML5" nowrap>
              {{ item?.file?.size / 1024 / 1024 | number: ".2" }} MB
            </td>
            <td *ngIf="uploader.options.isHTML5">
              <div class="progress" style="margin-bottom: 0">
                <div
                  class="progress-bar"
                  role="progressbar"
                  [ngStyle]="{ width: item.progress + '%' }"
                ></div>
              </div>
            </td>
            <td class="text-center">
              <span *ngIf="item.isSuccess"><i class="fa fa-check"></i></span>
              <span *ngIf="item.isCancel"><i class="fa fa-ban"></i></span>
              <span *ngIf="item.isError"><i class="fa fa-times"></i></span>
            </td>
            <td nowrap>
              <select
                (change)="onSelectFileType(item, $event.target.value)"
                [disabled]="item.isReady || item.isUploading || item.isSuccess"
              >
                <option value="-1">Select An Option</option>
                <option value="GEMEO_ARCHIVE">Gemeo Zip Archive</option>
                <option value="REPORT_LEVEL_XLSX">Report Levels XLSX</option>
                <option value="POSTAL_SECTOR_XLSX">PostalSector XLSX</option>
                <option value="POI_XLSX">POI XLSX</option>
                <option value="ONS_POSTCODE_CSV">ONS Postcode CSV</option>
                <option value="JCD_CLIENT_FILE_XML">InvoiceClient XML</option>
                <option value="JCD_CLIENT_FILE_CSV">InvoiceClient CSV</option>
                <option value="CHECKPOINT_XML">CheckPoint XML</option>
                <option value="CHECKPOINT_XML_ZIP">
                  CheckPoint XML Zip Archive
                </option>
                <option value="US_ZIPCODE_CSV">US Zipcode CSV</option>
                <option value="AU_POSTCODE_CSV">AU Postcode CSV</option>
                <option value="ES_POSTCODE_CSV">ES Postcode CSV</option>
                <option value="NO_POSTCODE_CSV">NO Postcode CSV</option>
                <option value="IT_POSTCODE_CSV">IT Postcode CSV</option>
                <option value="FI_POSTCODE_CSV">FI Postcode CSV</option>
                <option value="BE_POSTCODE_CSV">BE Postcode CSV</option>
                <option value="DE_POSTCODE_CSV">DE Postcode CSV</option>
                <option value="CAMPAIGN_JSON">Load Campaign JSON</option>
                <option value="DATA_DS_XML">DATA_DS Bookings XML</option>
                <option value="DATA_DS_XML_ZIP">
                  DATA_DS Bookings XML Zip
                </option>
              </select>
            </td>
            <td nowrap>
              <button
                type="button"
                class="btn btn-success btn-xs"
                (click)="item.upload()"
                [disabled]="item.isReady || item.isUploading || item.isSuccess"
              >
                <span class="fa fa-upload"></span> Upload
              </button>
              <button
                type="button"
                class="btn btn-warning btn-xs"
                (click)="item.cancel()"
                [disabled]="!item.isUploading"
              >
                <span class="fa fa-ban"></span> Cancel
              </button>
              <button
                type="button"
                class="btn btn-danger btn-xs"
                (click)="item.remove()"
              >
                <span class="fa fa-trash"></span> Remove
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div>
        <div>
          Queue progress:
          <div class="progress">
            <div
              class="progress-bar"
              role="progressbar"
              [ngStyle]="{ width: uploader.progress + '%' }"
            ></div>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-success btn-s"
          (click)="uploader.uploadAll()"
          [disabled]="!uploader.getNotUploadedItems().length"
        >
          <span class="fa fa-upload"></span> Upload all
        </button>
        <button
          type="button"
          class="btn btn-warning btn-s"
          (click)="uploader.cancelAll()"
          [disabled]="!uploader.isUploading"
        >
          <span class="fa fa-ban"></span> Cancel all
        </button>
        <button
          type="button"
          class="btn btn-danger btn-s"
          (click)="uploader.clearQueue()"
          [disabled]="!uploader.queue.length"
        >
          <span class="fa fa-trash"></span> Remove all
        </button>
      </div>
    </div>
  </div>

  <br /><br />
</div>

<toaster-container></toaster-container>
