
import { Component, OnInit } from '@angular/core';
import { HeaderService } from './components/header/header.service';
import { AuthorizeService } from './services/authorize.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  systemData = {};
  userBundle = {};
  userData = {};
  constructor(
    private authorizeService: AuthorizeService,
    private headerService: HeaderService
  ) {

  }
  ngOnInit() {
    this.headerService.marketChanged$.subscribe(market => {
      if (market) {
        this.checkForUserAuthorization();
      }
    });
  }

  checkForUserAuthorization() {
    this.authorizeService.isUserAuthorized().subscribe(res => {
      if (res.status === 'OK') {
        if (res.data) {
          this.systemData = res.data.systemData;
          this.userBundle = res.data.userBundle;
          this.userData = res.data.userData;
        }
      }
    });
  }
}
