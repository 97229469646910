import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GLOBAL } from 'src/app/app.global';
import { environment } from 'src/environments/environment';
import { HeaderService } from './header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(
    private appHeaderService: HeaderService
  ) { }

  ngOnInit(): void {
    GLOBAL.ACCESS_TOKEN = this.appHeaderService.getCookie('token');
    const configURL = this.appHeaderService.getCookie('configURL');
    if (GLOBAL.ACCESS_TOKEN && configURL) {
      this.appHeaderService.getLandingConfig(configURL).subscribe((config: any) => {
        const selectedMarket = this.appHeaderService.getCookie('market');
        let isMarketShifted = false;
        let defaultURL = '';
        let matchFound = false;
        if (config && config.dataloadutils) {
          for (const key in config.dataloadutils) {
            if (Object.prototype.hasOwnProperty.call(config.dataloadutils, key)) {
              const env = config.dataloadutils[key];
              if (env.markets.findIndex(market => market === selectedMarket) > -1) {
                isMarketShifted = key !== window.location.origin;
                matchFound = true;
              }
              if (env.default) {
                defaultURL = key;
              }
            }
          }
        }
        if (!isMarketShifted && !matchFound) {
          // selected market should be on default URL now
          isMarketShifted = defaultURL !== window.location.origin;
        }
        const availableMarkets = this.appHeaderService.getCookie(window.location.origin);
        if (!availableMarkets || JSON.parse(availableMarkets).indexOf(selectedMarket) === -1 || isMarketShifted) {
          window.location.replace(environment.landingAppUrl);
          return;
        }
        environment.api = this.appHeaderService.getCookie('api');
        GLOBAL.USER = this.appHeaderService.getCookie('user');
        GLOBAL.MARKET = selectedMarket;
        const marketList = this.appHeaderService.getCookie('marketList');
        GLOBAL.MARKET_CHANGABLE = marketList && JSON.parse(marketList).length;
        this.appHeaderService.updateMarket(selectedMarket);
        this.appHeaderService.cookieUpdated();
      });
    } else {
      window.location.replace(environment.landingAppUrl);
    }
  }

}
