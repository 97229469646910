import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ToasterService } from 'angular2-toaster';
import { NgxSpinnerService } from 'ngx-spinner';
import { getFormData } from '../utils/formData';
import { environment } from 'src/environments/environment';
import { GLOBAL } from '../app.global';
import { HeaderService } from '../components/header/header.service';
@Injectable({
  providedIn: 'root'
})
export class AuthorizeService {
  token = '';

  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private toasterService: ToasterService,
    private appHeaderService: HeaderService,

  ) { }

  isUserAuthorized(): Observable<any> {
    const URL = environment.api + '/Service/dataloadutils/config';
    const param = {
      action: 'getConfig'
    };
    return this.makeServerCall(param, URL, 'POST');
  }

  makeServerCall(params: any, serviceURL: string, method: string): Observable<any> {
    this.spinner.show();
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: this.token
    });
    const requestOptions = {
      headers,
      body: getFormData(params)
    };
    return this.http.request(
      method,
      serviceURL,
      requestOptions
    ).pipe(
      map(res => this.data(res)),
      catchError(err => this.handleError(err))
    );
  }

  data(res) {
    this.spinner.hide();
    return res;
  }

  private handleError(err) {
    this.spinner.hide();
    if (err instanceof HttpErrorResponse && (err.status === 403 || err.status === 401 || err.status === 404)) {
      GLOBAL.ACCESS_TOKEN = '';
      const domain = this.appHeaderService.getCookie('domain');
      this.appHeaderService.setCookie('status_code', String(err.status), 1, domain);
      location.assign(environment.landingAppUrl);
      return of(err as any);
    }
    this.toasterService.pop('error', 'Server Error', err.message);
    throw err;
  }

}
