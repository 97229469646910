<div class="footer">
  <div> Copyright © 2021 | VIOOH </div>
  <div class="version-info">
    <i *ngIf="isMarketChangeble" class="fa fa-refresh" (click)="handleRefreshEnvironment()"></i>
    {{userbundle['app.market']}} : {{selectedMarket}} |
    User: {{user}} |
    Back End Version: {{systemdata.version}} |
    Front End Version: {{frontEndVersion}}
  </div>
</div>
